import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';
import "./footer.scss";

export default function Footer() {
	return (
		<footer className="footer">
         <div className="footer-top">
            <Link to="/accessibility-declaration"> Deklaracja dostępności </Link>
            <Link to="/privacy-policy"> Polityka Prywatności </Link>
         </div>
         
			<FooterBottom />
		</footer>
	)
}