import usePost from "hooks/data/usePost";
import Loader from "components/general/Loader";
import HTMLReactParser from "html-react-parser";

export default function AccessibilityDeclaration() {

   const d = usePost(608336);

   return d ? (
      <div style={{ padding: "60px 0"}}>
         <div className="content-container"> 
            <h1> { d?.title } </h1>
            { HTMLReactParser( d?.content || "" )}
         </div>
      </div>
   ) : <Loader style={{ height: "300px" }}/>
}