import { Link } from 'react-router-dom';
import HeaderActions from './inner/HeaderActions';
import HeaderLogos from "./inner/HeaderLogos";
import HeaderAppButtons from "./inner/HeaderAppButtons";
import { Logo } from "svg/icons";

import "./styles/header.scss";
import "./styles/header-responsive.scss";
import "./styles/header-contrast.scss";


export default function Header() {
	return (
		<header className="header">

			<div className="header-top">
			
				<div className="header-logo">
               <Link to="/">
					   <Logo />
               </Link>
				</div>
				
				<HeaderLogos/>

				<HeaderActions />

				<HeaderAppButtons/>
			</div>
		</header>
	)
}